// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  //Firebase config
  firebase_config: {
    apiKey: "AIzaSyD9iDiSM_zqWmIJkmVXMIjPBI9SktReJIw",
    authDomain: "isf-ar-cora.firebaseapp.com",
    projectId: "isf-ar-cora",
    storageBucket: "isf-ar-cora.appspot.com",
    messagingSenderId: "483254580978",
    appId: "1:483254580978:web:0b4462c2c960b49ad3826b"
  },

  //Config APP
  config: {
    urlBE: 'https://cora.isf-argentina.org/api/isf',
    urlWeb: 'https://cora.isf-argentina.org'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
